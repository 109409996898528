@import '~react-image-gallery/styles/css/image-gallery.css';
@import '~cropperjs/dist/cropper.css';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}
